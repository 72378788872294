//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacturaServices from "../../../Services/FacturaServices";

const facturaServices = new FacturaServices();
export default {
  name: "marcarFacturacionModal",
  props: ["model", "esPublicoGeneral", "clienteId", "datoFiscal"],
  components: {
    NuevoDatoFiscalModal: () => import("./nuevoDatoFiscalModal"),
    BuscarDatosFiscales: () => import("./buscarDatosFiscales"),
  },
  data() {
    return {
      datoFiscalObj: "",
      buscarDatoFiscal: "",
      buscarDatosFiscalesModal: false,
      datosFiscalesCliente: [],
      publicoGeneralSeleccionado: false,
      datoFiscalEditar: "",
      nuevoRfcModal: false,
      datoFiscalPublicoGeneral: JSON.parse(localStorage.getItem("datoFiscalPublicoGeneral")),
    }
  },
  methods: {
    isEmpty: _.isEmpty,
    onClose() {
      this.model = false;
      this.$emit("on-close");
    },
    async onShow() {
      this.loader(true);
      this.datoFiscalObj = _.isEmpty(this.datoFiscal) ? "" : this.datoFiscal;
      this.buscarDatoFiscal = "";
      await this.obtenerDatosFiscalesCliente();
      await this.$nextTick(() => this.$refs.first.focus());
      this.loader(false);
    },
    obtenerDatosFiscalesCliente() {
      let filtros = {clienteId: this.clienteId, busqueda: "",};
      return facturaServices.buscarDatosFiscales(filtros)
        .then(datosFiscales => this.datosFiscalesCliente = datosFiscales)
        .catch(error => this.alertShow(error, "error"));
    },
    abrirModalRfc(isEdit = false) {
      if (_.isEmpty(this.datoFiscalObj) && isEdit) return;
      if (isEdit) this.datoFiscalEditar = _.clone(this.datoFiscalObj);
      else this.datoFiscalEditar = {};
      this.nuevoRfcModal = true;
    },
    obtenerDatoFiscal(datoFiscal) {
      this.datoFiscalObj = datoFiscal;
      this.obtenerDatosFiscalesCliente();
    },
    cargarRfcPublicoGeneral() {
      if (this.publicoGeneralSeleccionado) {
        this.datoFiscalObj = "";
        this.publicoGeneralSeleccionado = false;
      } else {
        this.datoFiscalObj = this.datoFiscalPublicoGeneral;
        this.publicoGeneralSeleccionado = true;
      }
    },
    marcarFacturacionDatoFiscal() {
      this.$emit("get-dato-fiscal", this.datoFiscalObj);
    },
  }
}
