import axiosUtils   from "./Requests/AxiosUtils";
import RequestAxios from "../Repositories/RequestAxios";

export default class FacturaServices {
  buscarDatosFiscales(filtros) {
    let url       = "facturaMostrador/buscarDatosFiscales",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  agregarDatoFiscal(params) {
    let url       = "facturaMostrador/agregarDatoFiscal",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  editarDatoFiscal(params) {
    let url       = "facturaMostrador/editarDatoFiscal",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  timbrar(params) {
    let url       = "facturaMostrador/timbrarFactura",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  obtenerPdf(filtros) {
    let url       = "facturaMostrador/descargarPdf",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  obtenerXml(filtros) {
    let url       = "facturaMostrador/descargarXml",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }
  enviarCorreo(params) {
    let url       = "facturaMostrador/enviarCorreo",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

}